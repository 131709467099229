<template>

    <!-- add customer modal starts here -->
    <transition name="modal">
        <div class="modalBg modal-active" v-if="customerModal.isOpen">
            <div class="modalLayers" @click.prevent="customerModal.isOpen = false"></div>
            <div class="modalBody">
                
                <div class="addProductTitle withBtn">
                    <h3>Customer Details</h3>
                    <div class="customerDetailformBtns">
                        <p class="p2" style="margin-right: 0px !important;">{{customerModal.isActive ? 'ACTIVE' : 'DISABLED'}}</p>
                        <div class="actionSwitch" style="display: inline-flex;align-items: center;">
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    id="addcustomer"
                                    v-model="customerModal.isActive"
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="oderSlideBox productSideBox" style="position: relative; top: 0px; right: 0; width: 100%">
                    <div id="London" class="tabcontent">
                        <div class="contaient-div horizontal-scroll-fix">
                            <ValidationObserver ref="customerFormObserver">

                                <!-- image field starts here -->
                                <div class="uploadNewUserImg">
                                    <div class="userDetailsImg">
                                        <img :src="customerModal.image_src ? customerModal.image_src : '/images/customer_noImage.png'" alt=""/>
                                    </div>
                                    <div class="uploadImgFile">
                                        <input 
                                            type="file" 
                                            accept="image/png,image/jpg,image/jpeg,image/webp"
                                            ref="customer_image_field"
                                            @change.prevent="addImageHandle" 
                                        />
                                        <button>
                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <!-- image field ends here -->

                                <form class="customerDetailFormBox" @submit.prevent="validate()">
                                    <div class="row">

                                        <!-- first name field starts here -->
                                        <div class="col-md-6">
                                            <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_first_name_field">First Name <span>*</span></label>
                                                    <div class="formContentBox">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="customer_first_name_field"
                                                            name="customer_first_name_field"
                                                            v-model="customerModal.first_name"
                                                            @input="handleFormat('f_name')"
                                                            placeholder="First name"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- first name field ends here -->

                                        <!-- last name field starts here -->
                                        <div class="col-md-6">
                                            <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_last_name_field">Last Name <span>*</span></label>
                                                    <div class="formContentBox">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="customer_last_name_field"
                                                            name="customer_last_name_field"
                                                            v-model="customerModal.last_name"
                                                            @input="handleFormat('l_name')"
                                                            placeholder="Last name"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- last name field ends here -->

                                        <!-- email field starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_email_field">Email <span>*</span></label>
                                                    <div class="formContentBox">
                                                        <input
                                                            type="email"
                                                            class="form-control"
                                                            id="customer_email_field"
                                                            name="customer_email_field"
                                                            v-model="customerModal.email"
                                                            placeholder="Email"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- email field ends here -->

                                        <!-- password field starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="password" :rules="{required:true,min:8,max:250}" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_password_field">Password <span>*</span></label>
                                                    <div class="tableFromFieldItem passwordBtnIconPd">
                                                        <a href="#." class="genPasswordBtn" @click.prevent="generatePassword(16)">Generate Password</a>
                                                        <input
                                                            :type="customerModal.passwordShow ? 'text' : 'password'"
                                                            class="form-control"
                                                            v-model="customerModal.password"
                                                            placeholder="Password"
                                                            autocomplete="true"
                                                            ref="passwordInput"
                                                            id="customer_password_field"
                                                            name="customer_password_field"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        <button 
                                                            type="button" 
                                                            class="passwordEyeBtn" 
                                                            @click.prevent="customerModal.passwordShow = !customerModal.passwordShow"
                                                        >
                                                            <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="customerModal.passwordShow">
                                                                <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                                                            </svg>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                                                <g id="eyeOpen" transform="translate(-355 -428)">
                                                                    <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                                                        <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"></path></g><rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none">
                                                                    </rect>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- password field ends here -->

                                        <!-- mobile no. field starts here -->
                                        <div class="col-md-4" id="mobile-no-field">
                                            <ValidationProvider name="contact no." :rules="countryIsSelected ? 'required' : ''" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="">Contact No. <span>*</span></label>
                                                    <div class="formContentBox">
                                                        <vue-tel-input 
                                                            v-model="customerModal.mobile_no"
                                                            mode="international"
                                                            :onlyCountries="allowedCountries"
                                                            :inputOptions="contactFieldOptions"
                                                            ref="contactNoField"
                                                            :dropdownOptions="contactFieldDropDownOptions"
                                                            :validCharactersOnly=true 
                                                            @input="contactInputHandle"
                                                            @validate="validateContactNo"
                                                            @country-changed="countryChangeHandle"
                                                            @focus="contactFieldFocusHandle"
                                                            @close="dropDownCloseHandle"
                                                        >
                                                        </vue-tel-input>
                                                        <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                                                        <span class="text-danger" v-else-if="!countryIsSelected && contactFieldTouched">
                                                            Select a country from the dropdown menu or add the country code before the contact no.
                                                        </span>
                                                        <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">Enter a valid contact no.</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- mobile no. field ends here -->

                                        <!-- country field starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                                            <div class="tableFromFieldBox">
                                                <label for="">Country <span>*</span></label>
                                                <div class="vueSelectBlock">
                                                    <Multiselect
                                                        v-model="selectedCustomerCountry"
                                                        :clearOnSelect="true"
                                                        :options="availableCountries"
                                                        label="name"
                                                        :multiple="false"
                                                        :object="true"
                                                        track-by="name"
                                                        :hideSelected="false"
                                                        :showNoResults="true"
                                                        placeholder="Select country"
                                                        :showOptions="true"
                                                        :searchable="true"
                                                        openDirection="bottom"
                                                        @select="countrySelectHandle($event)"
                                                    >
                                                        <span slot="noResult">No country found matching the searched keyword.</span>
                                                    </Multiselect>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- country field ends here -->

                                        <!-- state field starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="">State <span>*</span></label>
                                                    <div class="vueSelectBlock">
                                                        <Multiselect
                                                            v-model="selectedCustomerState"
                                                            :clearOnSelect="true"
                                                            :options="selectedCustomerCountryStates"
                                                            label="name"
                                                            track-by="name"
                                                            :hideSelected="false"
                                                            :showNoResults="true"
                                                            :multiple="false"
                                                            :object="false"
                                                            :showOptions="false"
                                                            :searchable="true"
                                                            openDirection="bottom"
                                                            :placeholder="selectedCustomerCountryStatesLoading ? 'Loading states....' : 'Select state'"
                                                            :loading="selectedCustomerCountryStatesLoading"
                                                            @select="stateSelectHandle($event,'add-customer')"
                                                        >
                                                            <span slot="noOptions">To load the states, please select a country.</span>
                                                            <span slot="noResult">No state found matching the searched keyword.</span>
                                                        </Multiselect>
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- state field ends here -->

                                        <!-- city field starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="">City <span>*</span></label>
                                                    <div class="vueSelectBlock">
                                                        <Multiselect
                                                            v-model="selectedCustomerCity"
                                                            :clearOnSelect="true"
                                                            :options="selectedCustomerStateCities"
                                                            label="name"
                                                            track-by="name"
                                                            :hideSelected="false"
                                                            :showNoResults="true"
                                                            :multiple="false"
                                                            :object="true"
                                                            :showOptions="true"
                                                            :searchable="true"
                                                            openDirection="bottom"
                                                            :placeholder="selectedCustomerStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                                                            :loading="selectedCustomerStateCitiesLoading"
                                                        >
                                                            <span slot="noOptions">To load the cities, please select a state.</span>
                                                            <span slot="noResult">No city found matching the searched keyword.</span>
                                                        </Multiselect>
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- city field ends here -->

                                        <!-- address line 1 field starts here -->
                                        <div class="col-md-6">
                                            <ValidationProvider name="address line 1" rules="required|max:250" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_address_line_1_field">Address Line 1 <span>*</span></label>
                                                    <div class="formContentBox">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="customer_address_line_1_field"
                                                            name="customer_address_line_1_field"
                                                            v-model="customerModal.address_line_1"
                                                            placeholder="Address line 1"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- address line 1 field ends here -->

                                        <!-- address line 2 field starts here -->
                                        <div class="col-md-6">
                                            <ValidationProvider name="address line 2" rules="max:250" v-slot="{ errors }">
                                                <div class="tableFromFieldBox">
                                                    <label for="customer_address_line_2_field">Address Line 2</label>
                                                    <div class="formContentBox">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="customer_address_line_2_field"
                                                            name="customer_address_line_2_field"
                                                            v-model="customerModal.address_line_2"
                                                            placeholder="Address line 2"
                                                        />
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- address line 2 field ends here -->

                                        <!-- saving button starts here -->
                                        <div class="col-md-12">
                                            <div class="formCustomerBtn">
                                                <loaderBtn v-if="customerModal.isLoading"/>
                                                <button v-else type="submit">Save</button>
                                            </div>
                                        </div>
                                        <!-- saving button ends here -->

                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>

                <div class="modalCloseIcon">
                    <button @click.prevent="customerModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>

            </div>
        </div>
    </transition>
    <!-- add customer modal ends here -->

</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";
import loaderBtn from "../loaderBtn.vue";

export default {
    name:"AddCustomerModal",
    data(){
        return {

            customerModal:{
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                passwordShow: false,
                mobile_no: "",
                address_line_1: "",
                address_line_2: "",
                image: "",
                image_src: "",
                isActive: true,
                isOpen: false,
                isLoading: false,
            },
            //customer modal country, state & city handle
            selectedCustomerCountry: null,
            selectedCustomerCountryStates: [],
            selectedCustomerCountryStatesLoading: false,
            selectedCustomerState: null,
            selectedCustomerStateCities: [],
            selectedCustomerStateCitiesLoading: false,
            selectedCustomerCity: null,

            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },

            allowedCountries:[],
            contactFieldOptions:{
                placeholder: "Contact no.",
                showDialCode: true,
                type: 'tel',
                maxlength: 25,
            },
            contactFieldDropDownOptions:{
                showSearchBox: true,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showFlags: true,
                placeholder: "Select a Country"
            },
            contactIsValid: true,
            countryIsSelected: false,
            contactFieldTouched: false,
            selectedContactObj: {},
            selectedContactCountry: [],

        }
    },
    props:{
    },
    components:{
        Multiselect,
        loaderBtn,
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
            storeAllCountries: 'countries_module/allCountries',
        }),
    },
    watch:{
        storeAllCountries:{
            handler:function(value){

                this.availableCountries = _.cloneDeep(value);

                this.availableCountries.forEach((country)=>{

                    this.allowedCountries.push(country.iso2);

                });

            },deep:true
        },
    },
    methods:{

        openModal(){

            this.customerModal = {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                passwordShow: false,
                mobile_no: "",
                address_line_1: "",
                address_line_2: "",
                image: "",
                image_src: "",
                isActive: true,
                isOpen: true,
                isLoading: false,
            }

            this.selectedCustomerCountry = [];
            this.selectedCustomerCountryStatesLoading = false;
            this.selectedCustomerState = [];
            this.selectedCustomerStateCitiesLoading = false;
            this.selectedCustomerCity = [];


            // this.contactFieldTouched = false;

            // this.$nextTick((_) => {

            //     if(this.$refs.customerFormObserver){

            //     this.$refs.customerFormObserver.reset();

            //     }

            // });



            // if(form == 'add-order'){
          
            //     this.$refs.addCustomerMultiSelect.toggle();

            // }else if(form == 'update-order'){
            
            //     this.$refs.updateCustomerMultiSelect.toggle();

            // }

        },

        generatePassword(length){

            let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}";
            let retVal = "";

            for(var i = 0, n = charset.length; i < length; ++i){

                retVal += charset.charAt(Math.floor(Math.random() * n));
                
            }

            this.$refs.passwordInput.value = retVal;
            this.customerModal.password = retVal;

            this.$refs.passwordInput.focus();

        },

        addImageHandle(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
            
            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    if(this.$refs.customer_image_field){

                        this.$refs.customer_image_field.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.customerModal.image = file;
                    this.customerModal.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

                if(this.$refs.customer_image_field){

                    this.$refs.customer_image_field.value = '';

                }

            }

        },
        handleFormat(field){

            if(field == 'f_name'){

                let fname = this.customerModal.first_name;
                fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
                fname = fname.replace(/\s+/g, ' ');
                fname = fname.replace(/^\w/, (c) => c.toUpperCase());
                
                this.customerModal.first_name = fname;

            }else if(field == 'l_name'){

                let lname = this.customerModal.last_name;
                lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
                lname = lname.replace(/\s+/g, ' ');
                lname = lname.replace(/^\w/, (c) => c.toUpperCase());

                this.customerModal.last_name = lname;

            }

        },

        //add customer contact methods starts here
        validateContactNo(contactObj){

            if(typeof contactObj.valid != 'undefined'){

                this.contactIsValid = contactObj.valid;
            
            }

        },
        contactInputHandle(number,numberObj){

            if(typeof numberObj.country == 'undefined'){
            
                this.countryIsSelected = false;

            }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
            
                this.countryIsSelected = false;

            }else if(typeof numberObj.country != 'undefined' && 
            typeof numberObj.valid == 'undefined' &&
            numberObj.formatted.length == numberObj.country.dialCode + 1){
            
                this.countryIsSelected = false;

            }else{

                this.countryIsSelected = true;
            
            }

            this.selectedContactObj = numberObj;

        },
        dropDownCloseHandle(){

            if((this.customerModal.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

                this.customerModal.mobile_no = "+" + this.selectedContactCountry.dialCode;

            }

            this.countryIsSelected = true;

        },
        countryChangeHandle(countryObj){

            this.selectedContactCountry = countryObj;

        },
        contactFieldFocusHandle(){

            this.contactFieldTouched = true;

        },
        //add customer contact methods ends here

        //country, state, city handling starts here
        countrySelectHandle(){

            this.selectedCustomerState = [];

            this.selectedCustomerCity = [];

            this.loadCountryStates();

        },
        stateSelectHandle(){

            this.selectedCustomerCity = [];

            this.loadStateCities();

        },

        async loadCountryStates(){

            let countryId = this.selectedCustomerCountry.id;

            this.selectedCustomerCountryStatesLoading = true;
            try{
                let res = await this.$axios.get('states/all',{params:{country_id:countryId}});
                if(res.data.status_code == "1010"){

                    this.selectedCustomerCountryStates = _.cloneDeep(res.data.states);

                }
            }catch(error){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.selectedCustomerCountryStatesLoading = false;

            }

        },
        async loadStateCities(){

            let stateId = this.selectedCustomerState.id;

            this.selectedCustomerStateCitiesLoading = true;
            try{
                let res = await this.$axios.get('cities/all',{params:{state_id:stateId}});
                if(res.data.status_code == "1010"){

                    this.selectedCustomerStateCities = _.cloneDeep(res.data.cities);

                }
            }catch(error){
                
                this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.selectedCustomerStateCitiesLoading = false;

            }

        },
        //country, state, city handling ends here


        async validate(){

            this.$refs.customerFormObserver.validate().then((success) => {

                if(!success){
                
                    const errors = Object.entries(this.$refs.customerFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.customerFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    if(typeof this.selectedContactObj.valid == 'undefined'){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                        document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

                    }else if(!this.selectedContactObj.valid){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                        document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

                    }

                }else{

                    if(typeof this.selectedContactObj.valid == 'undefined'){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                    }else if(!this.selectedContactObj.valid){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                        document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

                    }

                    if(this.contactIsValid && this.countryIsSelected){

                        this.handleAddCustomer();

                    }else{

                        document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }

                }

            });

        },  

        async handleAddCustomer(){

            let formData = new FormData();

            formData.append("first_name", this.customerModal.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append("last_name", this.customerModal.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
            formData.append("email", this.customerModal.email);
            formData.append("city", this.selectedCustomerCity.id);
            formData.append("address_line_1", this.customerModal.address_line_1.replace(/\s+/g,' ').trim());

            if(this.customerModal.address_line_2){

                formData.append('address_line_2', this.customerModal.address_line_2.replace(/\s+/g,' ').trim());

            }

            formData.append("password", this.customerModal.password);
            formData.append("mobile_no", this.customerModal.mobile_no.replace(/\s/g,''));

            formData.append("isActive", this.customerModal.isActive ? 1 : 0);

            if(this.customerModal.image != ""){

                formData.append("image", this.customerModal.image);

            }

            this.customerModal.isLoading = true;
            try{
                let res = await this.$axios.post("/addCustomer", formData);
                if(res.data.status_code == "1018"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
                    
                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    let customer = res.data.customer;
                    customer.isActive = customer.isActive ? true : false;

                    this.$emit('customer_add_success',customer);

                    this.customerModal.isOpen = false;

                }
            }catch(error){
                
                if(error.response){

                    if(error.response.data.error.includes("aleardy exists") || 
                    error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.customerModal.isLoading = false;

            }

        },

    },
    beforeMount(){

        if(!this.storeAllCountries || this.storeAllCountries.length == 0){

            this.$store.dispatch('countries_module/fetchAllCountries');

        }else{

            this.availableCountries = this.storeAllCountries;

            this.availableCountries.forEach((country)=>{

                this.allowedCountries.push(country.iso2);

            });

        }

    },
}
</script>

<style scoped>

    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>